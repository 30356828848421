<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관련공정 -->
          <c-process
            label="LBL0001705"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관리부서 -->
          <c-dept
            type="search"
            label="LBL0001721"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <!-- 동력기계 목록 -->
    <c-table
      ref="powerMachine"
      title="LBL0010375"
      tableId="powerMachine"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      rowKey="psiPowerEquipmentId"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            label="엑셀업로드"
            icon="upload"
            @btnClicked="excelUploadData" />
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="equipmentPopup" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'power-machine',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'psiPowerEquipmentNo',
            field: 'psiPowerEquipmentNo',
            // 동력기계번호
            label: 'LBL0010376',
            align: 'center',
            style: 'width:110px',
            sortable: true,
          },
          {
            name: 'powerMachineName',
            field: 'powerMachineName',
            // 동력기계명
            label: 'LBL0010377',
            align: 'left',
            type: 'link',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'specInfo',
            field: 'specInfo',
            // 명세
            label: 'LBL0010378',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'matQuality',
            field: 'matQuality',
            // 주요재질
            label: 'LBL0010379',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'motorVol',
            field: 'motorVol',
            // 전동기용량(KW)
            label: 'LBL0010380',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'protectType',
            field: 'protectType',
            // 방호.보호장치의 종류
            label: 'LBL0010381',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            // 비고
            label: 'LBLREMARK',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        powerMachineName: '',
        processCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.psi.pfi.power.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.equipmentPopup(row);
    },
    equipmentPopup(result) {
      this.popupOptions.target = () => import(`${"./powerMachineDetail.vue"}`);
      this.popupOptions.title = 'LBL0010382';   // 동력기계 상세
      this.popupOptions.param = {
        psiPowerEquipmentId: result ? result.psiPowerEquipmentId : '',
      };
      this.popupOptions.isFull = false;
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    excelUploadData() {
      this.popupOptions.title = '동력기계 업로드';
      this.popupOptions.target = () => import(`${'./powerMachineExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          // item.plantCd = this.searchParam.plantCd;
          item.SysRevision = 1;
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
        })

        this.$http.url = transactionConfig.psi.pfi.power.insert.url + '/excel';
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    },
  }
};
</script>
